<template>
  <div class="brand-list" v-loading="loading">
      <div class="areaFilters">
          <div class="areaFilters--label">地市</div>
          <div class="areaFilters--content">
              <div class="city">
                  <div class="items" :class="selectedAddressAll.cityId == 0 ? 'active' : ''" @click="handleClearCity('city')">全部</div>
                  <div class="items" :class="item.id == selectedAddressAll.cityId ? 'active' : ''" v-for="(item, index) in cityArr" :key="index" @click="handleSelectCity(item)">{{item.name}}</div>
              </div>
          </div>

          <div class="areaFilters--label">区/县</div>
          <div class="areaFilters--content">
              <div class="city">
                  <div class="items" :class="selectedAddressAll.district == 0 ? 'active' : ''" @click="handleClearDistrict('district')">不限</div>
                  <div class="items" :class="item.id == selectedAddressAll.district ? 'active' : ''" v-for="(item, index) in districtArr" :key="index" @click="handleSelectDistrict(item)">{{item.name}}</div>
              </div>
          </div>
          <div class="clear"></div>
      </div>

      <!-- 供应商 -->
      <div class="supplier">
          <div class="module-list-head" >
              <h2><span></span>本地生鲜供应商</h2>
          </div>
          <div class="module-list-body" v-if="supplierList">
              <vue-seamless-scroll :data="supplierList" :class-option="optionLeft" class="seamless-warp2">
                  <ul class="supplier-ul" :style="{width:widthData+'px'}">
                      <li class="supplier-item" v-for="(item, index) in supplierList" :key="index"
                          @click="toDetail(item.id)">
                          <div class="item-logo" >
                              <img v-if="item.logo" :src="$img(item.logo)" alt="">
                              <img v-else src="@/assets/images/shop-defaultImg.png" alt="" />
                          </div>
                          <h5>{{item.name}}</h5>
                          <p>{{item.address}}</p>
                      </li>
                  </ul>
              </vue-seamless-scroll>
          </div>
          <div class="module-list-body" v-else>
              <p>暂无该地区供应商！</p>
          </div>
      </div>

      <div class="separate-wrap" v-if="adListGG.adv_image != ''">
          <img :src="adListGG.adv_image" width="1210" height="100" />
      </div>

      <div class="feed-tab-wrapper">
          <ul class="feed-tab" >
              <li class="feed-tab__item" :class="filters.cat === 0? 'feed-tab__item--active' : ''" @click="selectedAllCategoryGoods()">
                  <div class="feed-tab__item-title">
                      <span class="feed-tab__item-title-text">全部</span>
                  </div>
              </li>
              <li class="feed-tab__item" v-for="(item, index) in categoryList" :key="index" :class="filters.cat === item.id ? 'feed-tab__item--active' : ''" @click="selectedCategoryGoods(item)">
                  <div class="feed-tab__item-title"><span class="feed-tab__item-title-text">{{item.name}}</span></div>
              </li>
          </ul>
      </div>

      <div class="list-wrap">
        <div class="cargo-list" v-if="cargoList.length">
          <div class="goods-info">
            <div
              class="item"
              v-for="(item, index) in cargoList" :key="index"
              @click="$router.pushToTab({ path: '/sku-' + item.id+ '?source_type='+filters.website_type})">
                <div class="goods-wrap">
                    <div class="img-wrap"><img alt="商品图片" :src="$img(item.image, { size: 'mid' })" @error="imageError(index)" /></div>
                    <h3>
<span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
<span class="p_tag_832" v-if="item.tag_level == 1">国贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
<span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>{{ item.title }}</h3>
                    <p class="desc" v-if="item.des">{{ item.des }}</p>
                    <p class="price">
                        <span class="num">￥{{ item.price }}</span>
                    </p>
                </div>
            </div>
          </div>
          <div class="pager">
            <el-pagination
                background
                :pager-count="5"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                @size-change="handlePageSizeChange"
                @current-change="handleCurrentPageChange"
                hide-on-single-page
            ></el-pagination>
          </div>
        </div>
        <div class="empty" v-else>
          <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
        </div>
      </div>
  </div>

</template>

<script>
import {supplierList, freshGoodsList} from "@/api/goods/goods";
import {getAreaList} from "@/api/address";
import {adList} from "@/api/website";

export default {
    name: "list",
    components: {
    },
    inject:["reload"],
    computed: {
        widthData() { return 432 * Number(this.supplierList.length); },
        optionLeft() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        },
    },
    data: () => {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 20,
            categoryList:[],
            cargoList:[],
            filters: {
                province: '',
                city: 0,
                district: 0,
                website_id: 0,
                site_id: 0,
                cat: 0,
                website_type: 1,
                type_id:0,
            },
            cityArr:[],
            districtArr:[],
            selectedAddressAll:{
                provinceId:0,
                cityId:0,
                district:0,
            },
            supplierList:[],
            adListGG:{
                adv_image:''
            },
            loading: true,
        }
    },
    created() {

    },
    mounted() {
        this.getFreshGoodsList() //产品列表
        this.getSupplierList() //供应商集合
        this.getAreaList(this.filters.province, 'city')
        this.getAdListGG()
    },
    methods: {
        getAdListGG() {
            adList({ keyword: 'NS_PC_OURFRESH' })
                .then(res => {
                    if(res.data.adv_list.length >0) {
                        this.adListGG = res.data.adv_list[0];
                    }
                })
        },
        handleSelectCity(item) {
            this.filters.city = item.id
            this.selectedAddressAll.cityId = item.id
            this.currentPage = 1
            this.getAreaList(item.id,'district')
            this.getFreshGoodsList() //产品列表
            this.getSupplierList() //供应商集合
        },
        handleClearCity() {
            this.selectedAddressAll.cityId = 0
            this.selectedAddressAll.district = 0
            this.filters.city = 0
            this.filters.district = 0
            this.districtArr =[]
            this.currentPage = 1
            this.getFreshGoodsList() //产品列表
            this.getSupplierList() //供应商集合
        },
        handleSelectDistrict(item) {
            this.filters.district = item.id
            this.selectedAddressAll.district = item.id
            this.currentPage = 1
            this.getFreshGoodsList() //产品列表
            this.getSupplierList() //供应商集合
        },
        handleClearDistrict() {
            this.filters.city = this.selectedAddressAll.cityId
            this.selectedAddressAll.district = 0
            this.filters.district = 0
            this.currentPage = 1
            this.getFreshGoodsList() //产品列表
            this.getSupplierList() //供应商集合
        },
        getAreaList(pid,type){
            getAreaList({pid:pid}).then(res =>{
                if(type === 'city'){
                    this.cityArr = res.data
                }else if(type === 'district'){
                    this.districtArr = res.data
                }
            })
        },
        getSupplierList(){
            supplierList(this.filters).then(res=>{
                if (res.code == 0 && res.data) {
                    this.supplierList = res.data
                }
            })
        },
        toDetail(site_id) {
            this.$router.pushToTab({
                path: "/shop-" + site_id
            })
        },
        selectedCategoryGoods(item){
            this.filters.cat = item.id
            this.currentPage = 1
            this.getFreshGoodsList() //产品列表
        },
        selectedAllCategoryGoods(){
            this.filters.cat = 0
            this.currentPage = 1
            this.getFreshGoodsList() //产品列表
        },
        getFreshGoodsList() {
            const params = {
                page: this.currentPage,
                pagesize: this.pageSize,
                keyword: this.keyword,
                ...this.filters
            }
            freshGoodsList(params || {})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.total = res.data.total
                        this.cargoList = res.data.items
                        this.categoryList = res.data.cats
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.currentPage =1
            this.getFreshGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getFreshGoodsList()
        },
        imageError(index) {
            this.cargoList[index].sku_image = this.defaultGoodsImage;
        },
    }
};
</script>
<style lang="scss">
    #app{
    background-color: #f7f7f7;
    }
</style>
<style lang="scss" scoped>
    .areaFilters{
        width: 1210px;
        background: #fff;
        margin: 20px auto;
        padding: 10px 0px 15px 0px;
        min-height: 60px;
        position: relative;
        border:2px solid #D81E01;
        border-radius: 8px;
        background-color: #fff;
        .areaFilters--label{
            float: left;
            margin-right: 10px;
            line-height: 30px;
            text-align: right;
            font-size: 18px;
            color: #D81E01;
            font-weight:600;
            width: 60px;
            display: block;
        }
        .areaFilters--content {
            float: left;
            width: 1140px;
            min-height: 30px;
            margin-bottom: 5px;
            display: block;
            .items {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
                font-size: 16px;
                line-height: 30px;
                color:#999;
            }
            .items:hover,.items.active {
                color: #d81e01;
            }
        }
        .clear{
            clear:both;
            zoom: 1;
        }
    }

    .feed-tab-wrapper{
        width: 1170px;
        padding:0px 20px;
        background-color:#D81E01 ;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .feed-tab {
            width: 1170px;
            margin: 0 auto;
            height: 48px;
            display: flex;
            .feed-tab__item {
                position: relative;
                height: 42px;
                margin-top:6px;
                width: 234px;
                float: left;
                text-align: center;
                cursor: pointer;
                .feed-tab__item-title {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 42px;
                }
                .feed-tab__item-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
            .feed-tab__item.feed-tab__item--active{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #fff;
                .feed-tab__item-title {
                    color: #D81E01;
                    font-size: 17px;
                }
            }
        }
    }

    .supplier {
        width: 1210px;
        display: block;
        margin: 0px auto 20px;
        padding: 10px 0px;
        background-color: #fff;
        border-radius: 8px;
        border-radius: 8px;

        .module-list-head {
            padding: 0px 10px;
            margin: 0;

            h2 {
                line-height: 42px;
                color: #333;
                padding: 10px 0px;
                font-size: 26px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
                vertical-align: middle;
                display: inline-block;

                span {
                    border: 4px solid $base-color;
                    border-radius: 6px;
                    height: 18px;
                    margin: 0px 10px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .module-list-body {
            padding: 0px 10px;
            margin: 10px 0px 20px;

            .seamless-warp2 {
                width: 1190px;
                height:132px;
                padding: 0;
                margin: 0;
                overflow: hidden;
                position: relative;
                ul {
                    padding: 0;
                    overflow: hidden;
                    height:132px;
                    position: relative;
                    float: left;

                    .supplier-item {
                        cursor: pointer;
                        width: 280px;
                        height: 130px;
                        background: #FFFFFF;
                        border: 1px solid #eee;
                        border-radius: 8px;
                        padding-left: 130px;
                        position: relative;
                        float: left;
                        margin-left: 20px;
                        margin-bottom: 20px;

                        .item-logo {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: 15px;
                            left: 15px;
                            background: #FFFFFF;
                            border-radius: 8px;
                            border: 1px solid #979797;
                            overflow: hidden;
                            line-height: 100px;
                        }

                        h5 {
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 600;
                            color: #301D1D;
                            line-height: 42px;
                            margin-top: 20px;
                        }

                        p {
                            margin: 0;
                            height: 20px;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #301D1D;
                            line-height: 18px;
                        }
                    }
                }
            }

        }
    }

    .separate-wrap{display: block;margin: 20px auto}

    .list-wrap {
        width: 1190px;
        overflow: hidden;
        min-height: 500px;
        background-color:#fff;
        padding: 10px 10px 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 30px;
        .goods-info {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 20%;
                margin: 0;
                background: #fff;
                cursor: pointer;
                padding:0;
                transition: all 0.2s linear;
                .goods-wrap{
                    margin: 0 10px 20px;
                    padding: 15px 0px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    min-height:225px;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .img-wrap {
                        width: 155px;
                        height: 155px;
                        margin: 0px auto;
                        text-align: center;
                        line-height: 160px;
                        /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                        /*border-radius: 16px;*/
                        overflow: hidden;
                        position:relative;
                        img {
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                        }
                    }
                    h3 {
                        font-size: 15px;
                        line-height: 24px;
                        height: 24px;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin: 10px 10px 5px;
                    }
                    .desc {
                        margin:0 10px;
                        height:14px;
                        font-size: 13px;
                        line-height:14px;
                        color: #b0b0b0;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .price {
                        margin:0 10px;
                        font-size: 13px;
                        /*text-align: center;*/
                        font-weight: 700;
                        color: $base-color;
                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }
                }
            }
        }
    }
    .empty {
        margin-top: 45px;
    }
    .pager {
        text-align: center;
        margin: 15px 0;
    }
</style>
